<template>
  <div>
    <a-modal
      :visible="visible"
      title="生产报工"
      :width="1400"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      okText="报工"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-row style="max-height: 400px; overflow: auto">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="dataItems"
            :pagination="false"
            :scroll="{ x: 2000 }"
            :loading="queryLoading"
          >
            <template slot="handler" slot-scope="value, item">
              <a-select v-model="item.handler" size="small" :allowClear="true" style="width: 120px">
                <a-select-option v-for="item in userItems" :key="item.id" :value="item.id" :item="item">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </template>
            <template slot="production_quantity" slot-scope="value, item">
              <a-input-number v-model="item.production_quantity" size="small" />
            </template>
            <template slot="unqualified_quantity" slot-scope="value, item">
              <a-input-number v-model="item.unqualified_quantity" size="small" />
            </template>

            <template slot="start_time" slot-scope="value, item">
              <a-date-picker
                v-model="item.start_time"
                placeholder="请选择时间"
                show-time
                size="small"
                valueFormat="YYYY-MM-DD HH:mm:ss"
              />
            </template>
            <template slot="end_time" slot-scope="value, item">
              <a-date-picker
                v-model="item.end_time"
                placeholder="请选择时间"
                show-time
                size="small"
                valueFormat="YYYY-MM-DD HH:mm:ss"
              />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { productionRecordCreate } from "@/api/production";
import { userOption } from "@/api/option";
import NP from "number-precision";

export default {
  props: ["visible", "items"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      queryLoading: false,
      confirmLoading: false,
      dataItems: [],
      userItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "production_order_number",
          width: 200,
        },
        {
          title: "工序",
          dataIndex: "process_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_name",
        },
        {
          title: "物料名称",
          dataIndex: "goods_number",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "排产数量",
          dataIndex: "total_quantity",
        },
        {
          title: "报工人",
          dataIndex: "handler",
          scopedSlots: { customRender: "handler" },
        },
        {
          title: "良品数量",
          dataIndex: "production_quantity",
          scopedSlots: { customRender: "production_quantity" },
        },
        {
          title: "不良品数量",
          dataIndex: "unqualified_quantity",
          scopedSlots: { customRender: "unqualified_quantity" },
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
          width: 240,
          scopedSlots: { customRender: "start_time" },
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
          width: 240,
          scopedSlots: { customRender: "end_time" },
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    async confirm() {
      const dataItems = this.dataItems.sort((a, b) => a.production_order_number - b.production_order_number);

      this.confirmLoading = true;
      for (const item of dataItems) {
        await productionRecordCreate(item);
      }
      this.$message.success("报工完成");
      this.confirmLoading = false;
      this.$emit("create");
      this.cancel();
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(value) {
      if (value) {
        userOption().then((data) => (this.userItems = data.results));

        this.queryLoading = true;
        const dataItems = [];
        for (const item of this.items) {
          dataItems.push({
            production_task: item.id,
            production_quantity: NP.minus(item.total_quantity, item.production_quantity),
            unqualified_quantity: 0,
            handler: this.userId,
            production_order_number: item.production_order_item.number,
            process_name: item.process_item?.name,
            goods_name: item.goods_item.name,
            goods_number: item.goods_item.number,
            goods_spec: item.goods_item.spec,
            total_quantity: item.total_quantity,
          });
        }
        this.dataItems = dataItems;
        this.queryLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
